<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div class="container">

            <br><br>
            
            <h4>API Docs</h4>
                    <h6>Aqui você pode verificar a documentação de nossas APIs</h6>

                    <br>
                    <div class="line"></div>
                    <br>

                    <!--  -->
                    <h4>Introdução</h4>
                    <h6>Nesta seção estão as informações básicas para acessar todas as APIs</h6>
                    <br>
                    <div>
                        <h6><strong>URL base para as requisições:</strong></h6>
                        <h6 class="blueArea" style="width: fit-content;">https://app.lgpd.center/api/v1/</h6>
                    </div>
                    <br>

                    <div class="col-md-12">
                        <h6><strong>Autenticação:</strong></h6>
                    </div>
                    <h6>A autenticação é feita através do fornecimento da sua API Key. Ela deve ser enviada em todas as requisições no header <span class="blueArea">Api-Key</span>. Caso a API Key seja inválida, não seja informada ou o header estiver incorreto, nossa API retornará <span class="blueArea">HTTP 401</span>.</h6>
                    <!-- <h6>
                        Para obter sua API Key acesse a área de
                        <a href="https://app.lgpd.center/painel/keys" target="_blank">Chaves de Acesso</a>
                        em nossa interface web.
                    </h6> -->
                    <!-- <h6>Para utilização das APIs é necessario cadastrar o site e o IP de origem na aba <a href="https://app.lgpd.center/admin/cadastro/sites" target="_blank">Sites</a> no painel web.</h6> -->
                    <br>

                    <div class="col-md-12">
                        <h6><strong>Obtenção de Chaves de Acesso Exclusivas:</strong></h6>
                    </div>
                    <h6>Para acessar os recursos da API, é necessário obter chaves de acesso exclusivas. Para solicitar suas chaves de acesso, envie os seguintes dados de origem da requisição:</h6>
                    <h6 class="mb-1"><b style="font-weight: 500;">URL da Requisição:</b> O URL completo da requisição feita à API.</h6>
                    <h6 class="mb-1"><b style="font-weight: 500;">Endereço IP de Origem:</b> O endereço IP a partir do qual a requisição será enviada.</h6>
                    <h6>Após recebermos esses dados, forneceremos suas chaves de acesso exclusivas, junto dos parâmetros de <b style="font-weight: 500;">'empresa'</b> e <b style="font-weight: 500;">'site'</b>, ambos utilizados para validação de suas chaves. As chaves são essenciais para autenticar suas requisições e garantir acesso aos recursos protegidos da API.</h6>
                    <h6>Por favor, note que as chaves de acesso são sensíveis e não devem ser compartilhadas com terceiros. Mantenha-as seguras para evitar o acesso não autorizado aos seus dados.</h6>
                    <h6>Para solicitar suas chaves de acesso, entre em contato conosco através dos canais designados.</h6>
                    <br>

                    <div class="col-md-12">
                        <h6><strong>Exemplo de header de autenticação:</strong></h6>
                    </div>

                    <div class="codeJSONDiv">
                        <div class="codeJSONHeader">
                            <div>JSON</div>
                        </div>
                        <div class="codeJSONBody">
                            <div class="jsonLine">&#123;</div>
                            <div class="jsonLine"><span class="t1">"Api-Key"</span>: <span class="t2">"sua_api_key"</span></div>
                            <div class="jsonLine">&#125;</div>
                        </div>
                    </div>

                    <br>

                    <div class="alertDiv mt-2">
                        Antes de realizar qualquer requisição, é necessária a obtenção de suas chaves de acesso exclusivas seguindo o procedimento acima, caso contrário a requisição será negada.
                    </div>

                    <br>
                    <div class="line mt-2"></div>
                    <br>

                    <!--  -->
                    <h4>API: Consentimento externo</h4>
                    <h6>Esta API é responsável por gerenciar consentimento de usuários</h6>
                    <br>

                    <div class="col-md-12">
                        <h6><strong>Rota:</strong></h6>
                        <h6>POST <span class="blueArea">/cliente/consentimento-externo</span></h6>
                    </div>

                    <br>

                    <div class="col-md-12">
                        <h6><strong>Body da requisição:</strong></h6>
                    </div>

                    <div class="codeJSONDiv">
                        <div class="codeJSONHeader">
                            <div>JSON</div>
                        </div>
                        <div class="codeJSONBody">
                            <div class="jsonLine">&#123;</div>
                            <div class="jsonLine"><span class="t1">"empresa"</span>: <span class="t2">"empresa"</span>, <span class="t3">// Parâmetro enviado junto com suas chaves</span></div>
                            <div class="jsonLine"><span class="t1">"email"</span>: <span class="t2">"email_do_usuario"</span>,</div>
                            <div class="jsonLine"><span class="t1">"nome"</span>: <span class="t2">"nome_do_usuario"</span>, <span class="t3">// Opcional - Nome do remetente do envio</span></div>
                            <div class="jsonLine"><span class="t1">"documento"</span>: <span class="t2">"documento_do_usuario"</span>, <span class="t3">// Opcional - Documento de identificação do remetente do envio</span></div>
                            <div class="jsonLine"><span class="t1">"data_nascimento"</span>: <span class="t2">"nascimento_do_usuario"</span>, <span class="t3">// Opcional - AAAA-MM-DD - Data de nascimento do remetente do envio</span></div>
                            <div class="jsonLine"><span class="t1">"titulo"</span>: <span class="t2">"titulo_do_consentimento"</span>, <span class="t3">// Opcional - Titulo do consentimento que sera exibido na plataforma e email.</span></div>
                            <div class="jsonLine"><span class="t1">"dominio"</span>: <span class="t2">"dominio_do_site"</span>, <span class="t3">// Opcional - Domínio do site onde o formulário foi preenchido</span></div>
                            <div class="jsonLine"><span class="t1">"site"</span>: <span class="t2">"nome_do_site"</span>, <span class="t3">// Parâmetro enviado junto com suas chaves</span></div>
                            <div class="jsonLine">&#125;</div>
                        </div>
                    </div>

                    <br>

                    <div class="col-md-12">
                        <h6><strong>Resposta:</strong></h6>
                    </div>

                    <div class="codeJSONDiv mb-2">
                        <div class="codeJSONHeader">
                            <div>JSON</div>
                        </div>
                        <div class="codeJSONBody">
                            <div class="jsonLine">&#123;</div>
                            <div class="jsonLine"><span class="t1">"message"</span>: <span class="t2">"Consentimento salvo com sucesso"</span></div>
                            <div class="jsonLine">&#125;</div>
                        </div>
                    </div>

        </div>

        <br><br>
    </div>

    <app-footer></app-footer>
</div>