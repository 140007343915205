<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container">
            <div class="row d-flex flex-center">
                <div class="col-md-12 col-sm-12 col-xs-12 d-flex flex-center">
                    <div class="sitemap-section-title">
                        <h1 class="text-left">Quality1.App - Sitemap</h1>  
                    </div>
                </div>           
            </div>

            <div class="row">

                <div class="col-md-3 col-sm-6 col-xs-6">
                    <div class="sitemap-section-title">
                        <h3 class="text-left">Quality1.App</h3>
                    </div>
                    <!-- <a href="<?= $url_br ?>sitemap" target="_blank"><p class="text-left"> O que é o Quality1.App</p></a>  -->
                    <a (click)="goPage(2)"><p class="text-left"> Diferenciais</p></a>
                    <a (click)="goPage(3)"><p class="text-left"> Porque o Quality1.App</p></a>
                    <a (click)="goPage(6)"><p class="text-left"> Perguntas Frequentes</p></a>
                    <a (click)="goPage(4)"><p class="text-left"> Planos</p></a>
                    <a [routerLink]="['/compare']"><p class="text-left"> Compare os planos</p></a>
                    <a [routerLink]="['/termos-e-condicoes']"><p class="text-left">Termos e Condições</p></a>
                    <a [routerLink]="['/nossos-parceiros']"><p class="text-left">Nossos Parceiros</p></a>
                    <a [routerLink]="['/nosso-time']"><p class="text-left">Nosso Time</p></a>
                    <a (click)="goPage(5)"><p class="text-left"> Contato</p></a>
                    <a href="https://quality1.app/app/status" target="_blank"><p class="text-left">Status</p></a>
                    <a href="https://quality1.app/app/releases" target="_blank"><p class="text-left">Releases</p></a>
                    <a href="https://quality1.app/app/noticias" target="_blank"><p class="text-left">Notícias</p></a>
                    <br>

                </div>
                
                <div class="col-md-3 col-md-offset-1 col-sm-6 col-xs-6">
                    <div class="sitemap-section-title">
                        <h3 class="text-left">LGPD</h3>
                    </div>
                    <a href="https://cyber.lgpd.center/portal/QualityManager#privacidade" target="_blank"><p class="text-left">Política de Privacidade</p></a>
                    <a href="https://cyber.lgpd.center/portal/QualityManager#cookies" target="_blank"><p class="text-left">Política de Cookies</p></a>
                    <a href="https://cyber.lgpd.center/portal/QualityManager#consentimento" target="_blank"><p class="text-left">Política de Consentimento</p></a>
                    <a href="https://cyber.lgpd.center/portal/QualityManager#canal" target="_blank"><p class="text-left">Canal de Comunicação</p></a>
                    <br>
                    <div class="sitemap-section-title">
                        <h3 class="text-left">Links Úteis</h3>
                    </div>
                    <a [routerLink]="['/assine']"><p class="text-left"> Assine Já</p></a>
                    <a href="https://quality1.com.br/" target="_blank"><p class="text-left">Login</p></a>
                    <a href="https://edu.quality1.app/" target="_blank"><p class="text-left">Treinamentos</p></a>
                    <a href="https://crm.cyber1group.com/knowledge-base" target="_blank"><p class="text-left">Ajuda Online</p></a>
                    

                </div>                

                <div class="col-md-4 hidden-sm hidden-xs">
                    <div class="about-left-img">
                        <picture class="d-none d-md-flex">
                            <img loading="lazy" src="/assets/images/telas-qm-2021-360px.webp" alt="Quality1.App - Telas em funcionamento">
                        </picture>                          
                    </div>
                </div>    
                <br><br>              

            </div>
                                  
        </div>

    </div>

    <app-footer></app-footer>
</div>