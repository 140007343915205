<div class="footer">
    <div class="container row">
        <div class="footerP1 col-sm-12 col-md-5">
            <div>
                <img loading="lazy" [routerLink]="['/']" src="assets/images/lgpd-center-branco.webp" alt="" style="cursor: pointer;" class="logo">
                <!-- <div class="d-flex justify-content-center">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div> -->
            </div>
        </div>
        <div class="footerP2 col-sm-12 col-md-7">
            <div>
                <span class="bottomLinksAll">
                    <span class="bottomLinks blueHover" (click)="goPage(5)">Contato</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="openURL('https://cyber.lgpd.center/portal#privacidade')">Política de Privacidade</span>
                    <span class="bottomLinksDiv">|</span>
                    <span (click)="openURL('https://cyber.lgpd.center/portal#cookies')" class="bottomLinks blueHover">Cookies</span>
                    <span class="bottomLinksDiv">|</span>
                    <span (click)="openURL('https://cyber.lgpd.center/portal#consentimento')" class="bottomLinks blueHover">Consentimento</span>
                    <span class="bottomLinksDiv">|</span>
                    <span (click)="openURL('https://cyber.lgpd.center/portal#canal')" class="bottomLinks blueHover">Canal Comunicação</span>
                </span>
                <div class="copyright">LGPD CENTER ® - Uma solução do Grupo Cyber1</div>
                <div class="copyright">Powered by <a href="https://mywebfy.com/" target="_blank" style="color: white;">MyWebFy</a></div>
                <!-- <div class="inpi">QualityManager® é <b (click)="marca()" style="color: white; cursor: pointer;" class="blueHover">marca registrada</b> no INPI - Instituto Nacional da Propriedade Industrial - sob o nº 901307823.</div>
                <div class="iqa"><span (click)="iqa()" class="blueHover">Certificado de Conformidade baseado na ABNT NBR ISO/IEC 29110-4-1:2020, emitido pelo IQA.</span></div>
                <div class="fy"><span (click)="webFy()" class="blueHover">Powered by MyWebFy</span></div> -->
            </div>
        </div>
    </div>
</div>