<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content container">
        <div style="height: 1px;"></div>
        <div style="margin-left: 13px;">
            <span class="title">Nossos Parceiros</span>
            <img loading="lazy" src="assets/icon/div2.webp" style="height: 12px;" alt="">
            <span class="subTitle">Conheça quem faz parte do nosso time!</span>
        </div>

        <div class="container mt-3">
            <div class="row">
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://aws.amazon.com/pt/?nc2=h_lg')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/Q1-aws-ws.webp" alt="">
                        </div>
                        <span class="txt">Estrutra de Datacenters para alocation de nossa plataforma on-line.</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://www.ariba.com/pt-br/ariba-network')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/sap-ariba.webp" alt="">
                        </div>
                        <span class="txt">Fornecedor credenciado no portal SAP Ariba Network -  uma rede de negócios que conecta parceiros.</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://cyber1group.com/')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/cyber1.webp" alt="">
                        </div>
                        <span class="txt">Empresa especializada em Proteção de Dados e Cyber Segurança.</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://sucuri.net/')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/sucuri.webp" alt="">
                        </div>
                        <span class="txt">Plataforma de segurança on-line com filtro de acessos e firewall em cloud.</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://www.hostgator.com.br/')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/hostgator.webp" alt="">
                        </div>
                        <span class="txt">Hosting de nossa infra estrutura de emails e treinamentos.</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://aws.amazon.com/pt/partners/')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/Q1-aws-apn.webp" alt="">
                        </div>
                        <span class="txt">A APN é uma comunidade global de parceiros AWS</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <!--  -->
                    <div class="cardq" (click)="openLink('https://sercan-consultoria.com.br/')">
                        <div class="photoDiv">
                            <img loading="lazy" class="img" src="/assets/parceiros/sercan.webp" alt="">
                        </div>
                        <span class="txt">Voltada à humanização da inteligência e competência.</span>
                    </div>
                </div>
            </div>
        </div>

        <br><br><br>
    </div>

    <app-footer></app-footer>
</div>