import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('partWhat') partWhat: any;
  @ViewChild('partDif') partDif: any;
  @ViewChild('partQ') partQ: any;
  @ViewChild('partPlans') partPlans: any;
  @ViewChild('partCont') partCont: any;
  @ViewChild('partPerg') partPerg: any;

  public accordionOpen = 0;

  form = new FormGroup({
  });

  constructor(private _snackBar: MatSnackBar, private event: EventService, private metaService: Meta) {}

  ngOnInit(): void {
    this.metaService.addTag( { name:'description', content:"Quality Manager, Home" });

    this.event.pageChange.subscribe(async (data: any) => {
      this.goSection(data);
    });
  }

  face() {
    let url = 'https://www.facebook.com/quality1app';
    window.open(url); 
  }
  linkedin() {
    let url = 'https://www.linkedin.com/company/quality1app';
    window.open(url); 
  }
  insta() {
    let url = 'https://www.instagram.com/quality1app/';
    window.open(url); 
  }
  twitter() {
    let url = 'https://twitter.com/qualitymanager/';
    window.open(url); 
  }

  faq () {
    let url = 'https://crm.cyber1group.com/knowledge-base';
    window.open(url); 
  }

  openAccor(i: number) {
    if (this.accordionOpen == i) {
      this.accordionOpen = 0;
      return;
    }

    this.accordionOpen = i;
  }

  goSection(i: number) {
    if (i == 1)
      this.partWhat.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (i == 2)
      this.partDif.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (i == 3)
      this.partQ.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (i == 4)
      this.partPlans.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (i == 5)
      this.partCont.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (i == 6)
      this.partPerg.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  sendEmail() {
    let url = "mailto:contato@cyber1group.com";
    window.open(url, "_blank");
  }

}
