import { Component } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private _snackBar: MatSnackBar, public event: EventService, public router: Router) { }

  marca() {
    let url = 'https://www.linkedin.com/feed/update/urn:li:activity:6956314519367225344';
    window.open(url);
  }

  policy() {
    let url = 'https://cyber.lgpd.center/portal/QualityManager#privacidade';
    window.open(url);
  }

  iqa() {
    let url = 'https://www.linkedin.com/feed/update/urn:li:activity:6967503634565595136/';
    window.open(url);
  }

  webFy() {
    let url = 'https://mywebfy.com/';
    window.open(url); 
  }

  face() {
    let url = 'https://www.facebook.com/quality1app';
    window.open(url); 
  }
  linkedin() {
    let url = 'https://www.linkedin.com/company/quality1app';
    window.open(url); 
  }
  insta() {
    let url = 'https://www.instagram.com/quality1app/';
    window.open(url); 
  }
  twitter() {
    let url = 'https://twitter.com/qualitymanager/';
    window.open(url); 
  }

  openURL(url: string) {
    window.open(url);
  }

  goPage(page: number) {
    if (page >= 1 && page <=5) {
      let route = this.router.url;
      this.router.navigate(['/home']);
      this.event.pageChange.emit(page);

      if (route != '/home') {
        setTimeout(()=>{
          this.event.pageChange.emit(page);
        }, 100)
      }
    } else {

    }
  }

  thisYear() {
    return new Date().getFullYear();
  }

}
