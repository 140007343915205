import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { DndDirective } from './diretives/dnd.directive';
import {MatDialogModule} from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FooterComponent } from './components/footer/footer.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SubscribeComponent } from './pages/subscribe/subscribe.component';
import { CompareComponent } from './pages/compare/compare.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { TermsComponent } from './pages/terms/terms.component';
import { TeamComponent } from './pages/team/team.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { TeamPersonComponent } from './pages/team/team-person/team-person.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {register} from 'swiper/element/bundle';
import { BandCardComponent } from './components/band-card/band-card.component';
import { ApiComponent } from './pages/api/api.component';

register();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    DndDirective,
    FooterComponent,
    SubscribeComponent,
    CompareComponent,
    FaqComponent,
    SitemapComponent,
    TermsComponent,
    TeamComponent,
    PartnersComponent,
    TeamPersonComponent,
    BandCardComponent,
    ApiComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ReactiveFormsModule,
    MatDialogModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
