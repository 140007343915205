<div>
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content" [formGroup]="form">
        
        <!--  -->
        <section class="p1">
            <div class="p1i d-flex w-100">
                <div class="container">
                    <div class="row h-100">
                        <div class="d-none d-lg-flex col-lg-6 h-100" style="align-items: center;">
                            <img loading="lazy" src="/assets/images/LGPD-banner.png" style="width: 100%; max-width: 520px;" alt="">
                        </div>
                        <div class="col-sm-12 col-lg-6 h-100 d-flex" style="justify-content: center; flex-direction: column;">
                            <span class="p1Title">
                                Garanta o consentimento adequado com nossa integração de formulários e mantenha um canal de comunicação eficaz entre seu DPO e os titulares dos dados.
                            </span>
                            <!-- <span class="p1SubTitle">Faça a Gestão online de PPAP com <br> LGPD Compliance</span> -->
                            <div class="d-flex w-100 justify-content-end" style="margin-top: 80px;">
                                <div class="btnGreen" style="margin-right: 13px;">
                                    <div class="icon"><ion-icon name="lock-closed"></ion-icon></div>
                                    100% Seguro
                                </div>
                                <div class="btnGreen" (click)="goSection(5)">
                                    <div class="icon"><ion-icon name="mail"></ion-icon></div>
                                    Fale conosco
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--  -->
        <section class="p2">

            <div style="margin-top: -90px;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-1-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">Canal de consentimento e</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">comunicação exclusivo e personalizado para sua empresa, além de ser…</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-2-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">integrado com seu site, e</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">com gestão única das políticas de consentimento, cookies e pedidos dos titulares,</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-3-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">pronto para uso!</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">Projetado para oferecer compliance em sua jornada ao LGPD.</span>
                                </div>
                                 <div #partWhat></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span class="p2Title">O que é o LGPD Center?</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="div">
            </div>
            <span class="p2SubTitle container">Uma ferramenta de gestão direcionada para os encarregados de proteção de dados.</span>

            <div class="container mt-5">
                <div class="row">
                    <!-- <div class="col-12 col-lg-6 d-none d-lg-flex">
                        <video alt="Q1.App system" autoplay loop>
                            <source src="/assets/images/Login-QM-sm.webm" type="video/webm">
                        </video>
                    </div> -->
                    <div class="col-12 col-lg-12 p2text">
                        O <b>LGPD Center</b> é a ferramenta que complementa a implementação do LGPD, através de um canal exclusivo de comunicação entre sua empresa e os titulares dos dados, gerindo todas as solicitações, garantindo rastreabilidade e conformidade, além de possibilitar a gestão das políticas de privacidade, consentimentos e de cookies. Nossa ferramenta é 100% baseada nos requisitos da Lei Geral de Proteção de Dados (Lei 13.709/2018), e traz todos os parâmetros necessários para que a empresa esteja em conformidade em poucos cliques.
                        <span style="display: block; text-align: center; font-weight: 600; font-style: italic; margin-top: 10px;">"Nossa plataforma complementa a implementação da gestão de privacidade, criando de forma rápida e segura um canal de comunicação e compliance de fácil gestão."</span>
                    </div>
                </div>
            </div>

            <div #partDif></div>
        </section>

        <!--  -->
        <section class="pGray">
            <div style="height: 1px;"></div>
            <span class="p2Title">Por que o LGPD Center?</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="div">
            </div>
            <span class="p2SubTitle container">O LGPD Center resolve o problema de comunicação entre sua empresa e as solicitações dos titulares.</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-lg-6 d-none d-lg-flex">
                        <img src="/assets/images/lgpd-monitor.webp" alt="">
                    </div>
                    <div class="col-12 col-lg-6 p2text">

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon>Canal de Solicitação dos titulares</span>
                            <span class="whySubTitle">A falta de procedimentos para atender os direitos dos titulares é um problema comum nas empresas, por isso criamos um espaço para gerenciar as tratativas e solicitações dos titulares dos dados através de um formulário de perguntas customizáveis tendo como base a rastreabilidade, validação e segurança de todas as solicitações.</span>
                        </div>

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon>Controle dos consentimentos</span>
                            <span class="whySubTitle">Painel dedicado e gerenciável para atender diversos tipos de consentimentos em diferentes locais, desde um formulário para o RH ou até mesmo em um sistema EAD, com gestão completa dos consentimentos recebidos e/ou revogados feitos através de uma função criptográfica que garante a integridade das solicitações.</span>
                        </div>

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon>Gestão automática de cookies</span>
                            <span class="whySubTitle">A partir de uma única interface é possível acompanhar, monitorar e gerenciar todos os cookies do website da sua empresa de forma simples e automática.</span>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div #partDif></div> -->
            <div #partQ></div>
        </section>

        <!--  -->
        <!-- <section class="pGray">
            <div style="height: 1px;"></div>

            <span class="p2Title">Diferenciais</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <span class="p2SubTitle container">Confira algumas características que tornam o Quality1.app único.</span>
            <br>

            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5 jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">LGPD Compliance</span>
                                <span class="difItemText tR">Aplicação totalmente em conformidade com a LGPD - Lei Geral de Proteção de Dados.</span>
                            </div>
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/implantacao-rapida.webp" alt="">
                            </div>
                        </div>

                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">Cyber Security</span>
                                <span class="difItemText tR">Infraestrutura baseada em segurança em compliance com família ISO 27000.</span>
                            </div>
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/sem-limite-usuarios.webp" alt="">
                            </div>
                        </div>


                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">100% Customizável</span>
                                <span class="difItemText tR">Capacidade de agregar novos conceitos, melhorias e alterações.</span>
                            </div>
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/suporte-integral.webp" alt="">
                            </div>
                        </div>


                    </div>

                    <div class="col-12 col-md-12 col-lg-4" style="display: flex; justify-content: center;">
                        <img loading="lazy" src="/assets/images/impressoes.webp" alt="" srcset="">
                    </div>

                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5">
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/customizavel.webp" alt="">
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Suporte</span>
                                <span class="difItemText tL">Atendimento diferenciado, updates normativos e patches sem custos adicionais.</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/escalavel.webp" alt="">
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Treinamentos</span>
                                <span class="difItemText tL">Oferecemos uma plataforma própria em EAD com certificado de conclusão.</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <img loading="lazy" class="difItemImg" src="/assets/icon/feature/backups.webp" alt="">
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Acessibilidade</span>
                                <span class="difItemText tL">Acesso rápido, fácil e seguro, sem necessidade de instalação.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section> -->

        <!--  -->
        <!-- <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <span class="pBlueTitle">Segurança baseada <br> em criptografia</span>
                            <span class="pBlueSubTitle mt-4">A maneira mais fácil de evidenciar todas as solicitações e consentimentos de forma rápida, simples e totalmente irrefutável, baseado em algoritmos matemáticos de criptografia.</span>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">Fale Conosco</button>
                        </div>
                    </div>
                </div>
                <div #partQ></div>
            </div>
        </section> -->

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <span class="p2Title">Como funciona o processo?</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <!-- <span class="p2SubTitle container">Vamos aos fatos... Você já passou por esses problemas?</span> -->

            <div class="container mt-5">
                <div class="row">

                    <div class="col-12 col-md-6">
                        <div>
                            <span class="whyTitle">1. Configuração</span>
                            <span class="whySubTitle">Após receber o login e senha da plataforma, você customizará as políticas e o canal de comunicação conforme as diretrizes de sua empresa, mas com a facilidade de contar com todas as politicas e perguntas previamente carregadas para sua análise e validação, tornando a experiência muito mais fácil e rápida.</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/botao-contrate.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-12">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/connector.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/personalize.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle">2. Personalize seu layout</span>
                            <span class="whySubTitle">Nesta etapa, você poderá criar um layout, escolhendo nome, idiomas, inserindo seu logotipo e outras opções para construir o portal com a identidade da sua marca. Com o layout definido será possível ativar seu portal e personalizá-lo com suas preferências de uso.</span>
                        </div>

                    </div>

                    <div class="col-12 col-md-12">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/connector-inverted.webp" alt="" style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle">3. Integre o portal ao seu website</span>
                            <span class="whySubTitle">Integração simples e rápida. Para integrar ao seu website opte pelo formato “link simples” ou “embutir via iframe”. Para seus formulários, integre nossa API com sua solução e obtenha uma gestão de consentimentos rápida e fácil.</span>
                        </div>

                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
                            <img src="assets/images/integre-ao-portal.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>


                </div>
                <div #partPlans></div>
            </div>

        </section>

        <!--  -->
        <section class="pGray" style="padding-bottom: 35px; min-height: 650px;">
            <div style="height: 1px;"></div>

            <span class="p2Title">Planos</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <span class="p2SubTitle container">Escolha a opção que melhor se adequa à sua necessidade.</span>

            <br>
            <div class="container mt-5">
                <div class="row" style="justify-content: center;">
                    <!-- <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan">
                            <span class="cardPlanTitle poppins">Startup</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">GRATIS</span>
                                <span class="priceSubtitle">1 acesso</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 acesso <b>simultâneo</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>via Ticket</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>EAD</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>1 Cliente</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>peças ilimitado</b></span></div>

                        </div>
                    </div> -->
                    <div class="col-12 col-md-6 col-lg-3">
                        <!--  -->
                        <div class="cardPlan">
                            <span class="cardPlanTitle poppins">Profissional (Mensal)</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">R$ 450,00</span>
                                <span class="priceSubtitle">/Mês</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span>  Canal de <b>Comunicação</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Controle <b>Consentimentos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span>  Gestão automática de <b>cookies</b></span></div>

                            <button (click)="goSection(5)" class="assineBtn">ASSINE JÁ</button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <!--  -->
                        <div class="cardPlan">
                            <span class="cardPlanTitle poppins">Profissional (Anual)</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">R$ 300,00</span>
                                <span class="priceSubtitle">/Mês</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span>  Canal de <b>Comunicação</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Controle <b>Consentimentos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span>  Gestão automática de <b>cookies</b></span></div>

                            <button (click)="goSection(5)" class="assineBtn">ASSINE JÁ</button>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan blueCard">
                            <span class="cardPlanTitle poppins black">Premium</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">R$ 200,00</span>
                                <span class="priceSubtitle">Por acesso</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 ou mais <b>acessos simultâneos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>via Ticket e E-mail</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>dedicados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Clientes <b>ilimitados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>peças ilimitado</b></span></div>

                        </div>
                    </div> -->
                    <!-- <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan">
                            <span class="cardPlanTitle poppins">Enterprise</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">CONSULTE</span>
                                <span class="priceSubtitle">&nbsp;</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 ou mais <b>acessos simultâneos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>dedicado por telefone</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>dedicados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Clientes e peças <b>ilimitados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> E mais benefícios, <b>compare...</b></span></div>

                        </div>
                    </div> -->
                </div>
            </div>

            <!-- <span [routerLink]="['/compare']" class="compareText poppins mb-3"> Compare cada plano e conheça seus diferencias, clique aqui!</span> -->
            <br>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <span class="pBlueTitle">Segurança baseada <br> em criptografia</span>
                            <span class="pBlueSubTitle mt-4">A maneira mais fácil de evidenciar todas as solicitações e consentimentos de forma rápida, simples e totalmente irrefutável, baseado em algoritmos matemáticos de criptografia.</span>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">Fale Conosco</button>
                        </div>
                    </div>
                </div>
                <div #partPerg></div>
            </div>
            <div #partCont></div>
        </section>

        <!--  -->
        <!-- <section class="pGray">
            <div style="height: 1px;"></div>

            <div class="container">

                <span class="p2Title">Perguntas Frequentes</span>
                <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                    <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
                </div>
                <span class="p2SubTitle container">Ainda tem dúvidas? Veja algumas das perguntas que sempre fazem pra nós!</span>

                <div class="w-100 d-block mt-5">

                    <div class="accor {{accordionOpen == 1 ? 'accorOpen' : ''}}" (click)="openAccor(1)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Está disponível o formulário do FMEA - VDA?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Sim! O Q1 está atualizado e o usuário pode escolher entre o formulário do FMEA 4ª Ed e o FMEA - VDA. Na utilização do FMEA-VDA a Prioridade de Ação (PA) é definida automaticamente pelo sistema de acordo com os critérios definido na tabela do manual.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 2 ? 'accorOpen' : ''}}" (click)="openAccor(2)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Como funciona o licenciamento do Quality?</span>
                        </div>
                        <span class="AccorRes poppins">
                            O Quality1.app é licenciado no formato SaaS, ou Software como Serviço, é um modelo de uso de software baseado na nuvem, ou seja, o sistema fica alojado remotamente e deve ser acessado via Internet.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 3 ? 'accorOpen' : ''}}" (click)="openAccor(3)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Há algum limite de usuário no Quality1.App?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Não temos limite de usuários e de cadastros, seja de peças, clientes ou fornecedores, você pode cadastrar quantos usuários desejar. O software é baseado em licenças de acesso simultâneo, que não limitam a quantidade de funcionários cadastrados no sistema.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 4 ? 'accorOpen' : ''}}" (click)="openAccor(4)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Qual a infraestrutura de TI que minha empresa precisa para instalar o Quality1.App?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Por ser um sistema web, o único requisito para que o sistema funcione é uma conexão de internet estável, nada mais.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 5 ? 'accorOpen' : ''}}" (click)="openAccor(5)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">A versão STARTUP é realmente gratuita? Consigo emitir um PPAP completo para meu cliente?</span>
                        </div>
                        <span class="AccorRes poppins">
                            SIM. totalmente gratuita e com todas as vantagens de nossa plataforma. Para saber mais sobre as diferenças entre os demais planos acesse: https://br.quality1.app/compare/.
                        </span>
                    </div>

                    <div class="w-100 w-100 d-flex justify-content-end">
                        <span (click)="faq()" class="compareText poppins mb-3">Ainda tem dúvidas? Veja o FAQ completo </span>
                    </div>

                </div>

            </div>
            <div #partCont></div>
        </section> -->

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <span class="p2Title">Entre em contato agora mesmo!</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>

            <div class="text-center mt-4">
                <span class="poppins d-flex city"> <ion-icon class="iconLast" name="location-sharp"></ion-icon> São Paulo – Brasil</span>
                <span class="poppins d-flex normal">Avenida Brigadeiro Faria Lima, 1811</span>
                <span class="poppins d-flex normal">Conjunto 1119 - Jardim América</span>
                <span class="poppins d-flex normal">CEP 01452-001</span>
                <br>
    
                <span class="poppins d-flex normal"> <ion-icon class="iconLast" name="call"></ion-icon> Tel.: (+55) 11 2189 0150</span>
                <br>
    
                <span (click)="sendEmail()" class="poppins d-flex normal blueHover" style="color: black;"> <ion-icon class="iconLast" name="send"></ion-icon> contato@cyber1group.com</span>

                <!-- <div class="d-flex justify-content-center mt-3 mb-3">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div> -->

            </div>

        </section>

    </div>

    <app-footer></app-footer>
</div>