import { Component } from '@angular/core';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent {

  ngOnInit() {
    // scroll up
    window.scrollTo(0, 0);
  }

}
